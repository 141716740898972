<template>
<v-main>
        <div class="mt-2">
          <v-card class="d-flex align-center card flat-card mb-2 py-6" color="surface" height="36">
            <label class="mr-4 font" for="switch">کالاهای ناموجود</label>
            <v-spacer></v-spacer>
           <v-switch class="ml-4" @change="onhand_change" id="switch" v-model="has_quantity"></v-switch>
          </v-card>
        </div>

        <!-- <categories/> -->
        <!-- <tags/> -->
        <costfilter/>
        <!-- <brand/> -->

        <v-row class="d-flex align-center justify-end ml-3 mt-5">
          <v-btn @click="remove_filter" class="primaryText--text" text>پاکسازی</v-btn>
          <v-btn @click="search_filter" class="button primaryActions onPrimaryActions--text mr-2">اعمال</v-btn>
        </v-row>
</v-main>
</template>
<script>
import brand from '../components/brandSelect'
import costfilter from './setCostFilter'
import tags from './tagFilter'
import categories from './categoryFilter'
import { search_product} from '../models/search'
import store from '@/store'
import { mapState } from 'vuex'
export default {
   components: {
      // brand,
      costfilter,
      // tags,
      // categories,
   },
   data: () => ({
      has_quantity: false,
   }),
   computed: {
     ...mapState({
       search_fild: state => state.search.searchFild,
     })
   },
   watch: {
      search_fild: {
        deep: true,
        handler: function () {
          this.has_quantity = !this.search_fild.has_quantity
        }
      }
    },
   created () {
     let filters = localStorage.getItem('filter_search')
        if (filters) {
          filters = JSON.parse(filters)
          this.has_quantity = !filters.has_quantity
        }
   },
   methods: {
     onhand_change () {
        let filters = localStorage.getItem('filter_search')
        if (filters) {
          filters = JSON.parse(filters)
        } else {
          filters = {}
        }
        if (!this.has_quantity) {
           filters.has_quantity = 1
          localStorage.setItem('filter_search', JSON.stringify(filters))
          store.dispatch('search_fild', {fild: 1 , type: 'has_quantity'})
        } else {
          filters.has_quantity = 0
          localStorage.setItem('filter_search', JSON.stringify(filters))
          store.dispatch('search_fild', {fild: 0 , type: 'has_quantity'})
        }
     },
     search_filter () {
        let filters = localStorage.getItem('filter_search')
        if (filters) {
          filters = JSON.parse(filters)
        } else {
          filters = {}
        }
        this.$emit('close', false)
       search_product(filters)
     },
     remove_filter () {
       let filters = localStorage.getItem('filter_search')
       if (filters) {
         filters = JSON.parse(filters)
         for (const filter in filters) {
           if (filter == 'has_quantity' || filter == 'min' || filter == 'max') {
             if (filter == 'has_quantity') {
               filters[filter] = 1
               store.dispatch('search_fild', {fild: 1 , type: 'has_quantity'})
             } else {
               filters[filter] = ''
             }
           }
         }
         localStorage.setItem('filter_search',JSON.stringify(filters))
       }
     }
   }
}
</script>
