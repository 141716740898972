<template>
  <div class="text-center">
    <v-bottom-sheet v-model="sheet" class="rounded-lg">
      <v-list>
        <v-list-item
          v-for="tile in tiles"
          :key="tile.title"
          @click="log_fild(tile)">
          <v-list-item-title>{{ tile.title }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-bottom-sheet>
  </div>
</template>

<script>
import store from '@/store'
import { mapState } from 'vuex'
import { search_product } from '../models/search'
  export default {
    props: ['sort'],
    data: () => ({
      sheet: false,
      tiles: [
        { title: 'پر فروش ترین ها', key: 1 },
        { title: 'محبوب ترین ها', key: 2 },
        { title: 'پر بازدید ترین ها', key: 3 },
        { title: 'جدید ترین ها', key: 4 },
        { title: 'قیمت', key: 5 },
        { title: 'پر تخفیف ترین ها', key: 6 }
      ],
    }),
    computed: {
      ...mapState({
        search_fild: state => state.search.searchFild
      })
    },
    watch: {
      'sort': {
        handler: function () {
            this.sheet = this.$props.sort
        }
      }
    },
    methods: {
      log_fild (tile) {
        this.sheet = false
        let filters = localStorage.getItem('filter_search')
        if (filters) {
          filters = JSON.parse(filters)
          filters.sort_field = tile.key
          filters.sort_type = 1
          localStorage.setItem('filter_search', JSON.stringify(filters))
        } else {
          filters = {}
          filters.sort_field = tile.key
          filters.sort_type = 1
          localStorage.setItem('filter_search', JSON.stringify(filters))
        }
        search_product(filters)
      }
    }
  }
</script>