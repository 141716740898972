<template>
  <div class="text-center">
   <v-select :items="items" flat multiple
    class="font card flat-card" color="surface" hide-details="" label="انتخاب دسته" 
    placeholder="انتخاب دسته" solo>
   </v-select>
  </div>
</template>
<script>
export default {
  data: () => ({
    items: ['کاله', 'میهن', 'مزمز', 'فارسی'],
  })
}
</script>