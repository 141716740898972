<template>
  <div class="mt-2">
  <v-select flat v-model="chips" :items="items" chips clearable class="font card flat-card" color="surface"
   label="همه برندها" placeholder="همه برندها" multiple solo>
    <template v-slot:selection="{ attrs, item, select, selected }">
      <v-chip
        v-bind="attrs"
        close
        class="accent onAccent--text"
        :input-value="selected"
        @click="select"
        @click:close="remove(item)">
        <span>{{ item }}</span>
      </v-chip>
    </template>
  </v-select>
  </div>
</template>
<script>
  export default {
    data () {
      return {
        items: ['کاله', 'میهن', 'مزمز', 'فارسی'],
      }
    },

    methods: {
      remove (item) {
        this.chips.splice(this.chips.indexOf(item), 1)
        this.chips = [...this.chips]
      },
    },
  }
</script>
