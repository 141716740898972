<template>
  <div class="text-center">
    <v-bottom-sheet v-model="sheet">
     <v-sheet class="text-center pb-5 px-2" color="background" height="540">
         <v-btn class="mt-3" text color="primary" @click="close_filter">
          بستن
        </v-btn>
       <filters @close= 'close' />
     </v-sheet>
    </v-bottom-sheet>
  </div>
</template>

<script>
import store from '@/store'
import filters from './filterSection'
  export default {
    props: ['filter'],
    components: {
      filters
    },
    data: () => ({
      sheet: false,
    }),
    watch: {
      'filter': {
        handler: function () {
            this.sheet = this.$props.filter
        }
      }
    },
    methods: {
      close_filter () {
        let filters = localStorage.getItem('filter_search')
       if (filters) {
         filters = JSON.parse(filters)
         for (const filter in filters) {
           if (filter == 'has_quantity' || filter == 'min' || filter == 'max') {
             if (filter == 'has_quantity') {
               filters.has_quantity = 1
               store.dispatch('search_fild', {fild: 1 , type: 'has_quantity'})
             } else {
               filters[filter] = ''
             }
           }
         }
         localStorage.setItem('filter_search',JSON.stringify(filters))
       }
       this.sheet = !this.sheet
      },
      close (close) {
        this.sheet = close
      }
    }
  }
</script>