<template>
     <v-select :items="items"
       class="font mt-2 card flat-card" color="surface" multiple chips hide-details="" label="تگ ها" 
       placeholder="تگ ها" solo>
         <template v-slot:selection="{ attrs, item, select, selected }">
        <v-chip v-bind="attrs" close class="accent onAccent--text" :input-value="selected" @click="select"
        @click:close="remove(item)">
        <span>{{ item }}</span>
        </v-chip>
      </template>
       </v-select>
</template>
<script>
export default {
  data: () => ({
    items: ['رژیمی', 'ارگانیک'],
  })
}
</script>