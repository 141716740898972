<template>
    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
        height="28"
        type="button"
          :color="color"
          class="button "
          dark
          v-bind="attrs"
          v-on="on">
          <span class="onSecondaryActions--text medium-font" v-text="minOrder"></span>
          <v-icon class="mr-auto onSecondaryActions--text">mdi-chevron-down</v-icon>
        </v-btn>
      </template>
      <v-virtual-scroll
      :items="selected"
      :item-height="50"
      height="300"
      class="surface"
    >
      <template v-slot:default="{ item }">
        <v-list-item>
          <v-list-item-content class="py-0" @click="addBasket(item)">
            <v-list-item-title  v-text="item.title"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-virtual-scroll>
    </v-menu>
</template>
<script>
import { mapState } from 'vuex'
import { addTObasket, removeTObasket } from '../../Basket/models/Basket'
  export default {
    data: () => ({
      selected: [{title: 'عدم انتخاب'}],
      minOrder: '',
      Order_count: ''
    }),
    props: ['slider', 'orders', 'color'],
    computed: {
      ...mapState({
        addBasket_error: state => state.basket.addBasket_error,
        addBasket_status: state => state.basket.addBasket_status
      })
    },
    watch: {
    addBasket_status: {
      handler: function () {
        if ( this.addBasket_error == false) {
          this.minOrder = this.Order_count
        } else {
          if (this.minOrder.split(" ")[0] == parseInt(this.$props.slider.min_order) * parseFloat(this.$props.slider.Packing_Weight)) {
            this.$emit('show',false)
          }
        }
      }
    }
  },
      mounted () {
        if (this.$props.orders.orderd == false) {
          let order = parseInt(this.$props.slider.min_order) * parseFloat(this.$props.slider.Packing_Weight)
          const min_order_ = parseInt(this.$props.slider.min_order) * parseFloat(this.$props.slider.Packing_Weight)
          this.Order_count = order + ' کیلوگرم '
          let lot_size = parseInt(this.$props.slider.lot_size)
          const Max_Order = Math.min(this.$props.slider.onhand, this.$props.slider.max_order)
          let min_order_while = parseInt(this.$props.slider.min_order)
          addTObasket({product: { product_id: this.$props.slider.id ,count: parseInt(this.$props.slider.min_order), org_id: this.$props.slider.org_id, weight: this.$props.slider.Packing_Weight},
            type: '+',
            price: 0
          })
          while (min_order_while <= Max_Order) {
            const select = { title: (order).toFixed(1) + ' کیلوگرم ', id: this.$props.slider.id }
            this.selected.push(select)
             lot_size = lot_size + parseInt(this.$props.slider.lot_size)
             min_order_while = min_order_while + parseInt(this.$props.slider.lot_size)
            order = min_order_ * lot_size
          }
        } else {
        let order = parseInt(this.$props.slider.min_order) * parseFloat(this.$props.slider.Packing_Weight)
        const min_order_ = parseInt(this.$props.slider.min_order) * parseFloat(this.$props.slider.Packing_Weight)
        this.minOrder = this.$props.orders.order.count + ' کیلوگرم '
        this.Order_count = order + ' کیلوگرم '
        let lot_size = parseInt(this.$props.slider.lot_size)
        const Max_Order = Math.min(this.$props.slider.onhand, this.$props.slider.max_order)
        let min_order_while = parseInt(this.$props.slider.min_order)
          while (min_order_while <= Max_Order) {
            const select = { title: order + ' کیلوگرم ', id: this.$props.slider.id }
            this.selected.push(select)
             lot_size = lot_size + parseInt(this.$props.slider.lot_size)
             min_order_while = min_order_while + parseInt(this.$props.slider.lot_size)
            order = min_order_ * lot_size
          }
        }
      },
      methods: {
        addBasket (item) {
          if (item.title == 'عدم انتخاب') {
            removeTObasket({product_id: this.$props.slider.id, org_id: this.$props.slider.org_id})
            this.$emit('show',false)
            return
          }
          this.Order_count = item.title
          const count = parseFloat(item.title.split(" ")[0]) / parseFloat(this.$props.slider.Packing_Weight)
            addTObasket({product: { product_id: this.$props.slider.id ,count: count, org_id: this.$props.slider.org_id, weight: this.$props.slider.Packing_Weight },
            type: '+',
            price: 0
          })
        }
      }
  }
</script>